import React from "react"
import HomepageData from "./data/extendedhomepage.json"
import CtaDisclosureNewtab from "@tightrope/ctadisclosurenewtab"
import CtaDisclosure from "@tightrope/ctadisclosure"
import FooterLinksSingle from "@tightrope/footerlinks/footerlinks_single.js"
import Styles from "./css/extendedhomepage.module.scss"
import {isChrome,isFirefox,isIeEdge,getBrowser} from "@tightrope/lpscripts/browserdetect"
class ExtendedHomepage extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      isChrome: isChrome(),
      isFirefox: isFirefox(),
      isIeEdge: isIeEdge(),
      display: 'none',
      pageData: props.data,
      data: HomepageData
    }
  }

  showBrowsers(browsers){
    let display = false;
    if(getBrowser() === 'ie' && browsers.includes('ie')){
      display=true;
    }
    if(getBrowser() === 'firefox' && browsers.includes('firefox')){
      display=true;
    }
    if(getBrowser() === 'chrome' && browsers.includes('chrome')){
      display=true;
    }
    if(getBrowser() === 'edge' && browsers.includes('edge')){
      display=true;
    }
    if(getBrowser() === 'other' && browsers.includes('other')){
      display=true;
    }
    if(display)
      this.setState({display: 'block'});
    else
      this.setState({display: 'none'});
  }

  componentDidMount() {
    this.props.data.then(data => {
      const instanceData = Object.assign(this.state.data, data);
      this.setState({ data: instanceData});
    this.state.isChrome = isChrome();
    this.state.isFirefox = isFirefox();
    this.state.isIeEdge = isIeEdge();

    if(isChrome()){
      const browserData = Object.assign(this.state.data, this.state.data.chrome);
      this.setState({data: browserData});
    }
    if(isFirefox()){
      const browserData = Object.assign(this.state.data, this.state.data.firefox);
      this.setState({data: browserData});
    }
        if (typeof window != "undefined") {
            window.mapParams();
        }
        if(this.props.browsers){
          this.showBrowsers(this.props.browsers);
        }
        else{
          this.setState({display: 'block'});
        }
    });
  }

  render() {
    let intro;
    if (this.state.data.introType === 'video') {
      intro = <video className={Styles.centerimage} loop autoPlay muted><source src={this.state.data.introMedia}></source></video>;
    }
    else if (this.state.data.introType === 'image') {
      intro = <img src={this.state.data.introMedia} className={Styles.centerimage} />;
    }
    else{
      intro = null;
    }
    let second;
    if (this.state.data.secondType === 'video') {
      second = <video className={Styles.secondVideo} loop autoPlay muted><source src={this.state.data.secondMedia}></source></video>;
    }
    else if (this.state.data.secondType === 'image') {
      second = <img src={this.state.data.secondMedia} className={Styles.centerimage} />;
    }
    else{
      second = null;
    }
    return (
      <div className = {Styles.extendedhomepage} style={{ display: this.state.display }} >
      <div className = {Styles.header}><img className = {Styles.toplogo} src={this.state.data.logo}/><span className = {Styles.pr}>{this.state.data.productName}</span>

      	<span className={Styles.pt}>{this.state.data.productType}
      </span>
      	</div>

      	<div className = {Styles.clear}></div>

      <div className = {Styles.allContainer}>


      <div className={Styles.top}>

      	<img className = {Styles.productImg} src={this.state.data.productImg}/>

      		<div className = {Styles.topRight}>
      		<h1 className = "hc_param">{this.state.data.productHeadline}</h1>
      			<h2 className={Styles.subhead}>{this.state.data.productType}</h2>
            <h3 className={Styles.newCopy}>{this.state.data.newCopy}</h3>

      			<button onClick = {() => window.triggerInstall()} data-cy="cta" className = {`${Styles.st2} ${Styles.bn_param} ${Styles.cws_dl}`}><span dangerouslySetInnerHTML={{ __html: this.state.data.mainCTA }}></span></button>
      			{this.props.children}
            </div>

      </div>{/*end top*/}

      <div className = {Styles.middle}>

      	<div className = {Styles.bottomInner}>
      	<h2 className = {Styles.headline}>{this.state.data.midHeadline}</h2>
      	{intro}


      	<p dangerouslySetInnerHTML={{ __html: this.state.data.description}}></p>

          <button onClick = {() => window.triggerInstall()} data-cy="cta" className = {`${Styles.st2} ${Styles.bn_param} ${Styles.cws_dl}`}><span dangerouslySetInnerHTML={{ __html: this.state.data.mainCTA }}></span></button>

<a href="/img/pdftab-sample.pdf" className={Styles.sample}>Download our sample PDF to learn how to use all the features of pdf.live!</a>
      		</div>


      </div>{/*end middle*/}


      <div className = {Styles.bottom}>

      		<h2 className = {Styles.headline}>{this.state.data.bottomHeadline}</h2>
      	<div className = {Styles.twocolInner}>
      		<div className = {Styles.left}>
            <div className = {Styles.howto}>
      				<div className = {Styles.arrow}></div><span dangerouslySetInnerHTML={{ __html: this.state.data.step1}}></span>
      				<div className = {Styles.arrow}>
              </div><span dangerouslySetInnerHTML={{ __html: this.state.data.step2}}></span>
      				<div className = {Styles.arrow}>
              </div><span dangerouslySetInnerHTML={{ __html: this.state.data.step3}}></span>
      			</div>

      </div>	{/*end left*/}

      		<div className = {Styles.right}>
      			{second}
      		</div>
      		</div>{/*end twocolInner*/}

          <button onClick = {() => window.triggerInstall()} data-cy="cta" className = {`${Styles.st2} ${Styles.bn_param} ${Styles.cws_dl}`}><span dangerouslySetInnerHTML={{ __html: this.state.data.mainCTA }}></span></button>

  </div>{/*end bottom*/}

      </div> {/*end allContainer*/}

            <FooterLinksSingle></FooterLinksSingle>

    </div>



    )
  }
}
export default ExtendedHomepage
